import { OAuthData } from '../contexts/AuthContext';
import { startProgress, stopProgress } from '../utils/nprogress';
import axios from './axios';

export const signUp: (data: {
  name: string;
  phoneNumber: string;
  email: string;
  password: string;
}) => any = async ({ name, phoneNumber, email, password }) => {
  const startTimeout = startProgress();

  try {
    await axios.post('/auth', {
      user: {
        email,
        password,
        gym_attributes: {
          name,
          phone_number: phoneNumber,
        },
      },
    });
  } finally {
    stopProgress(startTimeout);
  }
};

export const resendConfirmationEmail: (
  email: string
) => Promise<void> = async email => {
  const startTimeout = startProgress();

  try {
    await axios.post('/auth/confirmation', {
      user: {
        email,
      },
    });
  } finally {
    stopProgress(startTimeout);
  }
};

export const confirmEmail: (token: string) => Promise<void> = async token => {
  const startTimeout = startProgress();

  try {
    await axios.get('/auth/confirmation', {
      params: {
        confirmation_token: token,
      },
    });
  } finally {
    stopProgress(startTimeout);
  }
};

export const signIn: (
  email: string,
  password: string
) => Promise<OAuthData> = async (email, password) => {
  const startTimeout = startProgress();

  try {
    const { data } = await axios.post('/oauth/token', {
      grant_type: 'password',
      email,
      password,
      client_id: CONFIG.oAuthClientId,
    });

    return data;
  } finally {
    stopProgress(startTimeout);
  }
};

export const signOut = async (token: string): Promise<void> => {
  await axios.post('/oauth/revoke', {
    token,
    client_id: CONFIG.oAuthClientId,
  });
};

export const refreshToken = async (
  refreshToken: string
): Promise<OAuthData> => {
  const { data } = await axios.post('/oauth/token', {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    client_id: CONFIG.oAuthClientId,
  });

  return data;
};

export const updatePassword: (
  token: string,
  password: string
) => Promise<void> = async (token, password) => {
  const startTimeout = startProgress();

  try {
    await axios.put('/auth/password', {
      user: {
        reset_password_token: token,
        password,
      },
    });
  } finally {
    stopProgress(startTimeout);
  }
};

export const sendResetPasswordInstructions: (
  email: string
) => Promise<void> = async email => {
  const startTimeout = startProgress();

  try {
    await axios.post('/auth/password', {
      user: {
        email,
      },
    });
  } finally {
    stopProgress(startTimeout);
  }
};

export const acceptInvitation: (
  token: string,
  password: string
) => Promise<void> = async (token, password) => {
  const startTimeout = startProgress();

  try {
    await axios.put('/auth/invitation', {
      user: {
        invitation_token: token,
        password,
        password_confirmation: password,
      },
    });
  } finally {
    stopProgress(startTimeout);
  }
};
