import NProgress from 'nprogress';

const startTimeouts = new Set<NodeJS.Timeout>();

export const startProgress = (delay = 0): NodeJS.Timeout => {
  const startTimeout = setTimeout(NProgress.start, delay);
  startTimeouts.add(startTimeout);
  return startTimeout;
};

export const stopProgress = (startTimeout: NodeJS.Timeout): void => {
  clearTimeout(startTimeout);
  NProgress.done();
  startTimeouts.delete(startTimeout);
};
