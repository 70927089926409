import { Theme, useMediaQuery } from '@material-ui/core';
import { blueGrey, grey, red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import { useMemo } from 'react';

import { useSettings } from '../contexts/SettingsContext';

const useTheme = (): Theme => {
  const { themeMode = 'system' } = useSettings();

  const darkColor = grey[400];

  const preferDark = useMediaQuery('(prefers-color-scheme: dark)');

  const dark = useMemo(() => {
    if (themeMode === 'system') {
      return preferDark;
    } else {
      return themeMode === 'dark';
    }
  }, [preferDark, themeMode]);

  return useMemo(
    () =>
      createTheme({
        palette: {
          type: dark ? 'dark' : 'light',
          primary: {
            ...(dark ? { main: '#FF8689' } : { main: red[900] }),
          },
          secondary: {
            main: grey[400],
          },
          background: {
            default: dark ? '#191919' : grey[100],
          },
          ...(dark && { text: { primary: darkColor } }),
          ...(dark && { error: { main: '#FF8689' } }),
        },
        overrides: {
          MuiPaper: {
            root: {
              backgroundColor: dark ? '#202020' : grey[50],
            },
          },
          MuiFormControl: {
            root: {
              '& label.Mui-focused, & legend.Mui-focused': {
                color: 'inherit',
                '& span.MuiInputLabel-asterisk': {
                  color: 'inherit',
                },
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'inherit',
                },
                '& input:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 1000px ${
                    dark ? blueGrey[900] : blueGrey[50]
                  } inset`,
                },
              },
            },
          },
          MuiCssBaseline: {
            '@global': {
              body: {
                ...(dark && { color: darkColor }),
              },
            },
          },
          MuiSvgIcon: {
            root: {
              ...(dark && { color: darkColor }),
            },
          },
        },
        typography: {
          fontFamily: [
            'Montserrat',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
          fontSize: 14,
          h5: {
            fontSize: 22,
          },
          h6: {
            fontSize: 18,
          },
          subtitle1: {
            fontSize: 14,
          },
          body1: {
            fontSize: 14,
          },
        },
        props: {
          MuiTextField: {
            variant: 'outlined',
          },
          MuiFormControl: {
            variant: 'outlined',
          },
          MuiSelect: {
            variant: 'outlined',
          },
        },
      }),
    [dark, darkColor]
  );
};

export default useTheme;
