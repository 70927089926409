import { captureException } from '@sentry/nextjs';
import axios, { AxiosError, AxiosResponse } from 'axios';

const instance = axios.create({
  baseURL: CONFIG.apiUrl,
});

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    captureException(error);
    return Promise.reject(error);
  }
);

export default instance;
