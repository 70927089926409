import { captureException } from '@sentry/nextjs';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import store2 from 'store2';

export type ThemeMode = 'light' | 'system' | 'dark';

interface SettingsContextInterface {
  themeMode: ThemeMode;
  setThemeMode: (themeMode: ThemeMode) => void;
}

const SettingsContext = createContext<SettingsContextInterface>({
  themeMode: null,
  setThemeMode: () => '',
});

export const SettingsContextProvider: FC = props => {
  const [settings, setSettings] = useState<{ themeMode: ThemeMode }>(null);

  const setThemeMode = useCallback(themeMode => {
    setSettings(settings => ({ ...settings, themeMode }));
  }, []);

  const value = useMemo(
    () => ({
      ...settings,
      setThemeMode,
    }),
    [setThemeMode, settings]
  );

  useEffect(() => {
    if (settings && process.browser) {
      store2.set('settings', settings);
    }
  }, [settings]);

  useEffect(() => {
    if (process.browser) {
      let settings = { themeMode: 'system' as ThemeMode };
      try {
        const storedSettings = store2.get('settings');
        if (storedSettings) {
          settings = storedSettings;
        }
      } catch (error) {
        captureException(error);
      }

      setSettings(settings);
    }
  }, []);

  return <SettingsContext.Provider value={value} {...props} />;
};

export const useSettings = (): SettingsContextInterface => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error(
      `useSettings must be used within a SettingsContextProvider.`
    );
  }
  return context;
};
