import { camelCase, startCase } from 'lodash';

export const parseErrorMessages = (error: Record<string, any>): string => {
  let errorMessages =
    error?.response?.data?.errors ||
    (error?.response?.data?.error && [error?.response?.data?.error]) ||
    error?.errors ||
    (error?.message && [error?.message]) ||
    (error as any[]);

  if (Array.isArray(errorMessages) && errorMessages[0]?.message) {
    errorMessages = errorMessages.map(({ message }) => message);
  }

  if (!Array.isArray(errorMessages) && typeof errorMessages === 'object') {
    errorMessages = Object.entries(errorMessages).map(
      ([key, value]) =>
        `${startCase(camelCase(key))} ${
          Array.isArray(value) ? value.join(' ') : value
        }`
    );
  }

  return errorMessages?.join?.(', ');
};
