// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init } from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

init({
  dsn:
    SENTRY_DSN ||
    'https://7948c6c2bb4a4cd0b0ad17004008b565@o1091418.ingest.sentry.io/6108299',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,

  environment: CONFIG.sentryEnvironment,
  beforeBreadcrumb: breadcrumb => {
    if (breadcrumb.category === 'fetch') {
      const url: string = breadcrumb.data?.url ?? '';

      if (url.includes('/graphql')) {
        return null;
      }
    }

    return breadcrumb;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
