import '~/shared/styles/nprogress.scss';

import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  ServerError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ApolloProvider } from '@apollo/client/react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { createConsumer } from '@rails/actioncable';
import { captureException } from '@sentry/nextjs';
import { SentryLink } from 'apollo-link-sentry';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { ConfirmProvider } from 'material-ui-confirm';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SnackbarProvider, useSnackbar } from 'notistack';
import NProgress from 'nprogress';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import PageChangeProgressBar from '~/shared/components/PageChangeProgressBar';
import { MAINTENANCE_ROUTE } from '~/shared/constants/routes';
import {
  AuthContextProvider,
  getAccessToken,
  useAuth,
} from '~/shared/contexts/AuthContext';
import { SettingsContextProvider } from '~/shared/contexts/SettingsContext';
import useTheme from '~/shared/styles/useTheme';
import { parseErrorMessages } from '~/shared/utils/errorMessages';

const ThemedBasePage = ({ children }) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {children}
        </SnackbarProvider>
      </ConfirmProvider>
    </ThemeProvider>
  );
};

const ApolloContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { gymId, setOAuthData } = useAuth();
  const graphqlUri = useMemo(() => `${CONFIG.apiUrl}/graphql`, []);

  const hasSubscriptionOperation = useCallback(({ query: { definitions } }) => {
    return definitions.some(
      ({ kind, operation }) =>
        kind === 'OperationDefinition' && operation === 'subscription'
    );
  }, []);

  const httpLink = useMemo(
    () =>
      new HttpLink({
        uri: graphqlUri,
      }),
    [graphqlUri]
  );

  const splitLink = useMemo(() => {
    const cable = createConsumer(() => {
      const accessToken = getAccessToken();
      return `${CONFIG.apiUrl}/cable?access_token=${accessToken}`;
    });

    return ApolloLink.split(
      hasSubscriptionOperation,
      new ActionCableLink({ cable }),
      httpLink
    );
  }, [hasSubscriptionOperation, httpLink]);

  const authLink = useMemo(() => {
    return setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const accessToken = getAccessToken();
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: accessToken ? `Bearer ${accessToken}` : '',
          'X-Gym-Id': gymId,
        },
      };
    });
  }, [gymId]);

  const errorLink = useMemo(
    () =>
      onError(errorResponse => {
        const { graphQLErrors, networkError } = errorResponse;

        captureException(new ApolloError({ graphQLErrors, networkError }));

        if (graphQLErrors) {
          graphQLErrors.forEach(({ message: _0, locations: _1, path: _2 }) => {
            // console.error(
            //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            // )
          });

          const errorMessages = parseErrorMessages(graphQLErrors);

          enqueueSnackbar(errorMessages, {
            variant: 'error',
          });
        }

        if (networkError) {
          // console.error(`[Network error]: ${networkError}`);

          const errorMessages = parseErrorMessages(
            (networkError as ServerError).result
          );

          enqueueSnackbar(errorMessages, {
            variant: 'error',
          });

          if ((networkError as ServerError).statusCode === 401) {
            setOAuthData(null);
          }
        }
      }),
    [enqueueSnackbar, setOAuthData]
  );

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        link: from([
          new SentryLink({
            uri: graphqlUri,
            attachBreadcrumbs: {
              includeQuery: true,
              includeFetchResult: true,
              includeError: true,
            },
          }),
          errorLink,
          authLink,
          splitLink,
        ]),
        cache: new InMemoryCache(),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'cache-and-network',
          },
        },
      }),
    [authLink, errorLink, graphqlUri, splitLink]
  );
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

const MyApp: FC<AppProps> = ({ Component, pageProps }: AppProps) => {
  const { replace, pathname } = useRouter();

  const queryClient = new QueryClient();

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    NProgress.configure({ showSpinner: false });
  }, []);

  useEffect(() => {
    if (CONFIG.maintenanceMode && pathname !== MAINTENANCE_ROUTE) {
      replace(MAINTENANCE_ROUTE);
    }
  }, [pathname, replace]);

  return (
    <React.Fragment>
      <Head>
        <title>{'Fitquarters'}</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <script src="https://polyfill.io/v3/polyfill.min.js?features=Intl.NumberFormat,Intl.NumberFormat.~locale.en"></script>
      </Head>
      <PageChangeProgressBar>
        <AuthContextProvider>
          <SettingsContextProvider>
            <ThemedBasePage>
              <ApolloContextProvider>
                <QueryClientProvider client={queryClient}>
                  <Component {...pageProps} />
                </QueryClientProvider>
              </ApolloContextProvider>
            </ThemedBasePage>
          </SettingsContextProvider>
        </AuthContextProvider>
      </PageChangeProgressBar>
    </React.Fragment>
  );
};

export default MyApp;
