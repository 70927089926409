import { useRouter } from 'next/router';
import React, { FC, useEffect } from 'react';

import { startProgress, stopProgress } from '~/shared/utils/nprogress';

const PageChangeProgressBar: FC = ({ children }) => {
  const { events } = useRouter();

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }

    let startTimeout: NodeJS.Timeout;

    const startRouteProgress = () => {
      startTimeout = startProgress();
    };

    const stopRouteProgress = () => {
      stopProgress(startTimeout);
    };

    events.on('routeChangeStart', startRouteProgress);
    events.on('routeChangeComplete', stopRouteProgress);
    events.on('routeChangeError', stopRouteProgress);

    return () => {
      events.off('routeChangeStart', startRouteProgress);
      events.off('routeChangeComplete', stopRouteProgress);
      events.off('routeChangeError', stopRouteProgress);
    };
  }, [events]);

  return <>{children}</>;
};

export default PageChangeProgressBar;
